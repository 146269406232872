const brandBlockButtons = document.querySelectorAll(".brand_block_type");
const brandBlocks = document.querySelectorAll(".brand_block");
const allBrandsBlock = document.getElementById("all_brands_block");

if (brandBlockButtons && brandBlocks && allBrandsBlock) {
  function showAllBrandBlocks() {
    //show all brand blocks
    brandBlocks.forEach((brandBlock) => {
      brandBlock.style.display = "block";
    });

    //remove the highlight from all buttons
    brandBlockButtons.forEach((brandBlockButton) => {
      brandBlockButton.classList.remove("text-primary");
    });

    //set highlight for show all button
    document.getElementById("all_brands_block").classList.add("text-primary");
  }

  function hideAllBrandBlocksButOne(evt) {
    // remove all blocks
    brandBlocks.forEach((brand_block) => {
      brand_block.style.display = "none";
    });

    //remove the highlight from all buttons
    brandBlockButtons.forEach((brandBlockButton) => {
      brandBlockButton.classList.remove("text-primary");
    });

    //show the selected block
    let blockId = evt.target.dataset.brandBlockId;
    document.getElementById(blockId).style.display = "block";

    //add highlight to button
    let buttonId = evt.target.dataset.blockButtonId;
    document.getElementById(buttonId).classList.add("text-primary");

    //to be sure, remove highlight from 'show all' button
    allBrandsBlock.classList.remove("text-primary");
  }

  let block;
  brandBlockButtons.forEach((block) => {
    block.addEventListener("click", hideAllBrandBlocksButOne);
  });
  allBrandsBlock.addEventListener("click", showAllBrandBlocks);
}
