const accItems = document.querySelectorAll(".accordionItem");
const accHDs = document.querySelectorAll(".accordionItemHeading");

if (accItems.length > 0) {
  accHDs.forEach((accHD, index) => {
    accHD.addEventListener("click", () => {
      toggleItem(index);
    });
  });
  function toggleItem(index) {
    if (accItems[index].classList.contains("close")) {
      accItems[index].classList.remove("close");
      accItems[index].classList.add("open");
    } else {
      accItems[index].classList.add("close");
      accItems[index].classList.remove("open");
    }
  }

  // DISABLED THIS DUE To => a11y-accordion-tabs.min.js automatically starting the accordionTabs with .js-tabs selector
  // const tabs = document.querySelectorAll(".js-tabs");
  //
  // if (tabs) {
  //     tabs.forEach((element) => {
  //         new AccordionTabs(element, {
  //             breakpoint: 800,
  //             tabsAllowed: true,
  //             selectedTab: 2,
  //             startCollapsed: false
  //         });
  //
  //         $(document).ready(function() {
  //             $('[data-fancybox="gallery"]').fancybox({
  //                 // Options will go here
  //                 arrows: true,
  //                 toolbar: true,
  //                 buttons: [
  //                     // "zoom",
  //                     // "share",
  //                     // "slideShow",
  //                     // "fullScreen",
  //                     // "download",
  //                     // "thumbs",
  //                     "close"
  //                 ]
  //             });
  //
  //             // Add missing jQuery method
  //             jQuery.fn.addBack = jQuery.fn.andSelf;
  //         });
  //     })
  // }
}
