// This javascript is used for the add/remove to moodboard functionality of the 'new' content block styles.

const V2_MOODBOARD_PRODUCT_COUNTERS = [
  document.getElementById("moodboard_product_count"),
  document.getElementById("moodboard_product_count_mobile"),
];
const V2_MOODBOARD_MODIFY_PRODUCTS_FUNCTION = function (
  event,
  productID,
  method,
  classToHide,
  reload
) {
  event.preventDefault();

  fetch(`/mijn-favorieten/${productID}`, { method: method })
    .then((response) => response.json())
    .then((response) =>
      Array.from(V2_MOODBOARD_PRODUCT_COUNTERS).forEach(
        (element) => (element.innerText = response.moodboard_products || 0)
      )
    )
    .then(function () {
      if (reload) {
        location.reload();
        return;
      }

      document
        .querySelectorAll(`[data-product-id=\"${productID}\"]`)
        .forEach(function (element) {
          const classToShow =
            classToHide === "moodboard-remove-product"
              ? "moodboard-add-product"
              : "moodboard-remove-product";

          if (element.classList.contains(classToHide)) {
            element.classList.add("hidden");
          }
          if (element.classList.contains(classToShow)) {
            element.classList.remove("hidden");
          }
        });
    });
};

const V2_MOODBOARD_ADD_PRODUCT_BUTTONS = document.getElementsByClassName(
  "moodboard-add-product"
);
const V2_MOODBOARD_REMOVE_PRODUCT_BUTTONS = document.getElementsByClassName(
  "moodboard-remove-product"
);
const V2_MOODBOARD_UPDATE_PRODUCT_INPUTS = document.getElementsByClassName(
  "moodboard-update-product"
);

Array.from(V2_MOODBOARD_ADD_PRODUCT_BUTTONS).forEach((element) =>
  element.addEventListener("click", function (event) {
    const productID = this.getAttribute("data-product-id");
    const reload = this.getAttribute("data-reload") === "true";
    V2_MOODBOARD_MODIFY_PRODUCTS_FUNCTION(
      event,
      productID,
      "POST",
      "moodboard-add-product",
      reload
    );
  })
);

Array.from(V2_MOODBOARD_REMOVE_PRODUCT_BUTTONS).forEach((element) =>
  element.addEventListener("click", function (event) {
    const productID = this.getAttribute("data-product-id");
    const reload = this.getAttribute("data-reload") === "true";
    V2_MOODBOARD_MODIFY_PRODUCTS_FUNCTION(
      event,
      productID,
      "DELETE",
      "moodboard-remove-product",
      reload
    );
  })
);

Array.from(V2_MOODBOARD_UPDATE_PRODUCT_INPUTS).forEach((element) =>
  element.addEventListener("input", function (event) {
    const productID = this.getAttribute("data-product-id");
    fetch(`/mijn-favorieten/${productID}`, {
      method: "PUT",
      body: JSON.stringify({ quantity: element.value }),
    });
  })
);
