import Swiper from "swiper";
var Swipes = new Swiper(".home-main-slider", {
  loop: true,
  speed: 500,
  effect: "fade",
  autoplay: {
    delay: 5000,
  },
  // navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  // },
});
var ContentSwipes = new Swiper(".content-slider", {
  loop: false,
  speed: 500,
  effect: "fade",
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
var PartnerSlider = new Swiper(".partner-slider", {
  loop: true,
  speed: 500,
  autoplay: true,
  slidesPerView: 6,
  spaceBetween: 40,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 20,
      slidesPerColumn: 2,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesPerColumn: 2,
    },
    // when window width is >= 640px
    1279: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
var ProductSlider = new Swiper(".product-slider", {
  loop: true,
  speed: 500,
  autoplay: false,
  slidesPerView: 6,
  spaceBetween: 10,
  breakpoints: {
    // when window width is >= 320px
    480: {
      slidesPerView: 1,
    },
    // when window width is >= 480px
    767: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    // when window width is >= 640px
    1024: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

var galleryThumbs = new Swiper(".gallery-thumbs", {
  spaceBetween: 10,
  slidesPerView: 6,
  slidesPerColumn: 99,
  slidesPerColumnFill: "row",
  freeMode: false,
  watchSlidesVisibility: true,
  observer: true,
  observeParents: true,
  allowTouchMove: false,

  breakpoints: {
    // when window width is >= 320px
    767: {
      slidesPerView: 3,
      slidesPerColumn: 99,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
    },
    // 1200: {
    //   slidesPerView: 6,
    //   slidesPerColumn: 2,
    // }
  },
});
var galleryTop = new Swiper(".gallery-top", {
  spaceBetween: 10,
  watchOverflow: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  preventInteractionOnTransition: true,
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  thumbs: {
    swiper: galleryThumbs,
  },
});

galleryTop.on("slideChangeTransitionStart", function () {
  galleryThumbs.slideTo(galleryTop.activeIndex);
});

galleryThumbs.on("transitionStart", function () {
  galleryTop.slideTo(galleryThumbs.activeIndex);
});

var refThumbs = new Swiper(".reference-gallery-thumbs", {
  // loop: true,
  slidesPerView: 4,
  slidesPerColumn: 2,
  slidesPerColumnFill: "row",
  freeMode: true,
  watchSlidesVisibility: true,
  observer: true,
  observeParents: true,
  direction: "vertical",
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    991: {
      direction: "horizontal",
      slidesPerView: 4,
      slidesPerColumn: 1,
    },
  },
});

var refMain = new Swiper(".reference-slider-gallery", {
  // loop: true,
  watchOverflow: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  preventInteractionOnTransition: true,
  navigation: {
    nextEl: ".swiper-button-next-main",
    prevEl: ".swiper-button-prev-main",
  },
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  thumbs: {
    swiper: refThumbs,
  },
});

refMain.on("slideChangeTransitionStart", function () {
  refThumbs.slideTo(refMain.activeIndex);
});

refThumbs.on("transitionStart", function () {
  refMain.slideTo(refThumbs.activeIndex);
});

// Header logic

window.onscroll = function () {
  toggleStickyClass();
  scripts();
};

window.onresize = function () {
  toggleStickyClass();
  scripts();
};

let header = document.getElementById("header");
let sticky = header.offsetTop + "143";

function toggleStickyClass() {
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}

function scripts() {
  let headerHeight = $("#header").innerHeight();
  let menuTabHeight = $("#header .mobile-menu .menu-tab").innerHeight();
  let topPosition = (headerHeight - menuTabHeight) / 2;

  if (window.innerWidth < 767) {
    $(".mobile-menu .menu-tab").css("top", topPosition);
    $(".mobile-menu .menu-hide").css("top", topPosition + menuTabHeight);
  } else {
    $(".mobile-menu .menu-tab").removeAttr("style");
    $(".mobile-menu .menu-hide").removeAttr("style");
  }
}

(function ($) {
  scripts();

  $.fn.searchBox = function (ev) {
    var $searchEl = $(".search-elem");
    var $searchField = $("#search-field");

    $(document).keyup(function (e) {
      if (ev === "open" && e.key === "Escape") {
        $(this).searchBox("close");
      }
    });

    if (ev === "open") {
      $searchEl.addClass("search-open");
      $searchField.focus();
    }

    if (ev === "close") {
      $searchEl.removeClass("search-open");
    }

    $(".submit").prop("disabled", true);
    $("#search-field").keyup(function () {
      if ($(this).val() != "") {
        $(".submit").prop("disabled", false);
      }
    });
  };

  $(".desktop-menu .menu-tab ul li a").click(function (e) {
    let selectedMenuId = $(this).attr("id");
    let activeSubMenuId = `sub-${selectedMenuId}`;
    if ($(`#${activeSubMenuId}`).length) {
      e.preventDefault();
      $(".desktop-menu .menu-tab ul li").removeClass("active");
      $(this).parent().addClass("active");
      $(".desktop-menu .menu-hide .desktop-sub-menu").removeClass("show");
      $(`#${activeSubMenuId}`).addClass("show");
    }
  });
})(jQuery);

$(".search-btn").on("click", function (e) {
  $(this).searchBox("open");
  e.preventDefault();
});

$(".search-close").on("click", function () {
  $(this).searchBox("close");
});

// javascript code for mobile menu
const pageHeader = document.querySelector(".menu");
const openMenu = document.querySelector(".mobile-menu-open");
const closeMenu = document.querySelector(".menu .menu-close");
const menuWrapper = pageHeader.querySelector(".menu-wrapper");
const level1Links = pageHeader.querySelectorAll(".level-1 > li > a");
const listWrapper2 = pageHeader.querySelector(".list-wrapper:nth-child(2)");
const listWrapper3 = pageHeader.querySelector(".list-wrapper:nth-child(3)");
const subMenuWrapper2 = listWrapper2.querySelector(".sub-menu-wrapper");
const subMenuWrapper3 = listWrapper3.querySelector(".sub-menu-wrapper");
const backOneLevelBtns = pageHeader.querySelectorAll(".back-one-level");
const isVisibleClass = "is-visible";
const isActiveClass = "is-active";

// openMenu.addEventListener("click", function () {
//   menuWrapper.classList.toggle(isVisibleClass);
//   if (!this.classList.contains(isVisibleClass)) {
//     listWrapper2.classList.remove(isVisibleClass);
//     listWrapper3.classList.remove(isVisibleClass);
//     const menuLinks = menuWrapper.querySelectorAll("a");
//     for (const menuLink of menuLinks) {
//       menuLink.classList.remove(isActiveClass);
//     }
//   }
// });
// closeMenu.addEventListener("click", function () {
//   menuWrapper.classList.toggle(isVisibleClass);
//   if (!this.classList.contains(isVisibleClass)) {
//     listWrapper2.classList.remove(isVisibleClass);
//     listWrapper3.classList.remove(isVisibleClass);
//     const menuLinks = menuWrapper.querySelectorAll("a");
//     for (const menuLink of menuLinks) {
//       menuLink.classList.remove(isActiveClass);
//     }
//   }
// });
//
for (const level1Link of level1Links) {
  level1Link.addEventListener("click", function (e) {
    const parentText = level1Link.innerHTML;
    const siblingList = level1Link.nextElementSibling;
    if (siblingList) {
      e.preventDefault();
      subMenuWrapper2.parentElement.querySelector("span").innerHTML =
        parentText;
      this.classList.add(isActiveClass);
      const cloneSiblingList = siblingList.cloneNode(true);
      subMenuWrapper2.innerHTML = "";
      subMenuWrapper2.append(cloneSiblingList);
      listWrapper2.classList.add(isVisibleClass);
    }
  });
}

listWrapper2.addEventListener("click", function (e) {
  const target = e.target;
  if (target.tagName.toLowerCase() === "a" && target.nextElementSibling) {
    const siblingList = target.nextElementSibling;
    const parentText = target.innerHTML;
    e.preventDefault();
    target.classList.add(isActiveClass);
    const cloneSiblingList = siblingList.cloneNode(true);
    subMenuWrapper3.parentElement.querySelector("span").innerHTML = parentText;
    subMenuWrapper3.innerHTML = "";
    subMenuWrapper3.append(cloneSiblingList);
    listWrapper3.classList.add(isVisibleClass);
  }
});

for (const backOneLevelBtn of backOneLevelBtns) {
  backOneLevelBtn.addEventListener("click", function () {
    const parent = this.closest(".list-wrapper");
    parent.classList.remove(isVisibleClass);
    parent.previousElementSibling
      .querySelector(".is-active")
      .classList.remove(isActiveClass);
  });
}

// Extracted from team.html.php
// var accItem = document.getElementsByClassName("accordionItem");
// var accItemHeight = document.getElementsByClassName(
//   "accordionItemHeading"
// ).clientHeight;
// console.log(accItemHeight);
//
// var accHD = document.getElementsByClassName("accordionItemHeading");
// for (i = 0; i < accHD.length; i++) {
//   accHD[i].addEventListener("click", toggleItem, false);
// }
// function toggleItem() {
//   var itemClass = this.parentNode.className;
//   for (i = 0; i < accItem.length; i++) {
//     accItem[i].className = "accordionItem close";
//   }
//   if (itemClass == "accordionItem close") {
//     this.parentNode.className = "accordionItem open";
//   }
// }

// Extracted from product_detail.html.php
//
// var accItem = document.getElementsByClassName('accordionItem');
// var accHD = document.getElementsByClassName('accordionItemHeading');
// for (i = 0; i < accHD.length; i++) {
//   accHD[i].addEventListener('click', toggleItem, false);
// }
// function toggleItem() {
//   var itemClass = this.parentNode.className;
//   for (i = 0; i < accItem.length; i++) {
//     accItem[i].className = 'accordionItem close';
//   }
//   if (itemClass == 'accordionItem close') {
//     this.parentNode.className = 'accordionItem open';
//   }
// }
// var tabs = document.getElementsByClassName("js-tabs");
// new AccordionTabs(tabs, {
//   breakpoint: 800,
//   tabsAllowed: true,
//   selectedTab: 2,
//   startCollapsed: false
// });
// $(document).ready(function() {
//   $('[data-fancybox="gallery"]').fancybox({
//     // Options will go here
//     arrows: true,
//     toolbar: true,
//     buttons: [
//       // "zoom",
//       // "share",
//       // "slideShow",
//       // "fullScreen",
//       // "download",
//       // "thumbs",
//       "close"
//     ]
//   });
//
//
//   // Add missing jQuery method
//   window.jQuery.fn.addBack = window.jQuery.fn.andSelf;
//
//   console.info( window.jQuery.fn.jquery );
//
// });
