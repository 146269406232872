document.querySelectorAll('.photo-gallery').forEach(element => {
    const prevButton = element.querySelector('.photo-gallery-previous');
    const nextButton = element.querySelector('.photo-gallery-next');
    const thumbnailContainer = element.querySelector('.photo-gallery-thumbnails');
    const thumbnails = thumbnailContainer.querySelectorAll('.photo-gallery-thumbnail');

    function slideTo(direction)
    {
        const gapSize = 16;
        const thumbnailSize = thumbnails[0].clientWidth;
        const multiplier = 3;
        const offset = (thumbnailSize + gapSize) * multiplier;

        if (direction === 'left') {
            thumbnailContainer.scrollLeft -= offset;
        } else {
            thumbnailContainer.scrollLeft += offset;
        }
    }

    prevButton.addEventListener('click', event => slideTo('left'));
    nextButton.addEventListener('click', event => slideTo('right'));
    thumbnails.forEach(thumbnail => {
        thumbnail.addEventListener('click', event => {
            const selectedSlideId = parseInt(thumbnail.dataset.slide);
            element.querySelectorAll('.photo-gallery-main').forEach(main => main.classList.add('hidden'));
            element.querySelector(`.photo-gallery-main[data-slide="${selectedSlideId}"]`).classList.toggle('hidden');
        });
    });
});