window.addEventListener("load", (event) => {
  document.querySelectorAll("img[srcset]").forEach((element) => {
    const width = element.getBoundingClientRect().width;
    if (!width) return;
    element.setAttribute(
      "sizes",
      `${Math.ceil((width / window.innerWidth) * 100)}vw`
    );
  });
});
