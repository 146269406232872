document.getElementById("search-field").addEventListener("input", (event) => {
  const searchAutocompleteResults = document.getElementById(
    "search-autocomplete-results"
  );
  const value = event.target.value;

  if (!value || value.length < 3) {
    searchAutocompleteResults.innerHTML = "";
    return;
  }

  fetch(`/zoeken/autocomplete?q=${value}`, { method: "GET" })
    .then((response) => response.json())
    .then((response) => {
      // Reset any previously rendered results.
      searchAutocompleteResults.innerHTML = "";
      const autocompleteList = document.createElement("ol");
      autocompleteList.classList.add("autocomplete-list");

      response.forEach((set) => {
        if (set.results.length === 0) {
          return;
        }

        const wrapper = document.createElement("li");
        const heading = document.createElement("span");
        heading.classList.add("autocomplete-title");
        heading.textContent = set.title;

        const resultWrapper = document.createElement("ol");
        resultWrapper.classList.add("autocomplete-section");

        set.results.forEach((result) => {
          const itemWrapper = document.createElement("li");
          const item = document.createElement("a");
          item.classList.add("autocomplete-result");
          item.setAttribute("href", result.url);
          item.textContent = result.name;

          itemWrapper.append(item);
          resultWrapper.append(itemWrapper);
        });

        wrapper.append(heading);
        wrapper.append(resultWrapper);
        autocompleteList.append(wrapper);
      });

      searchAutocompleteResults.append(autocompleteList);
    });
});
