// Automatically append the csrf token as hidden field to all posted forms.
const csrfToken = document.querySelector("meta[name='csrf-token']").content;

document.querySelectorAll("form[method='post']").forEach(form => {
    form.addEventListener('submit', event => {
        event.preventDefault();

        const formData = new FormData(form);
        formData.append("csrf-token", csrfToken);

        const csrfInput = document.createElement('input');
        csrfInput.setAttribute('name', "csrf_token");
        csrfInput.setAttribute('value', csrfToken);
        csrfInput.setAttribute('type', "hidden");

        form.appendChild(csrfInput);

        form.submit();
    })
})