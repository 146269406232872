/**
 * DPI frontend team blocks logic.
 */

const TEAM_ACCORDION_CLASS_PREFIX = ".team-accordion__";
let teamEmployeeDetailContainer = document.getElementById(
  "team-employee-detail-container"
);
const teamAccordions = document.querySelectorAll(".team-accordion");
const teamAccordionButtons = document.querySelectorAll(
  `${TEAM_ACCORDION_CLASS_PREFIX}button`
);
let openTeamEmployeeAccordion: Element | null;

function closeTeamAccordion(accordion: Element) {
  accordion.removeAttribute("style");
  teamAccordionButtons.forEach((teamAccordion: Element) => {
    teamAccordion.classList.remove("opacity-50");
  });
  accordion.classList.remove("team-accordion--open");
}

function openTeamAccordion(accordion: Element) {
  if (openTeamEmployeeAccordion) {
    closeTeamAccordion(openTeamEmployeeAccordion);
  }
  teamAccordionButtons.forEach((teamAccordionButton: Element) => {
    teamAccordionButton.classList.add("opacity-50");
  });
  const content = accordion.querySelector(
    `${TEAM_ACCORDION_CLASS_PREFIX}content`
  );
  if (!content) {
    return;
  }
  accordion.setAttribute(
    "style",
    `padding-bottom:${content.clientHeight + 20}px;`
  );
  accordion.classList.add("team-accordion--open");
  openTeamEmployeeAccordion = accordion;

  accordion.scrollIntoView();
}

function getTeamEmployeeDetailContainer() {
  const teamEmployeeDetailContainer = document.getElementById(
    "team-employee-detail-container"
  );
  if (!openTeamEmployeeAccordion) {
    return;
  }
  const content = openTeamEmployeeAccordion.querySelector(
    `${TEAM_ACCORDION_CLASS_PREFIX}content`
  );
  if (!content || !teamEmployeeDetailContainer) {
    return;
  }
  openTeamEmployeeAccordion.setAttribute(
    "style",
    `padding-bottom:${content.clientHeight + 20}px;`
  );
  content.setAttribute(
    "style",
    `width:${teamEmployeeDetailContainer.clientWidth}px`
  );
}

if (teamEmployeeDetailContainer && teamAccordionButtons) {
  window.addEventListener("resize", getTeamEmployeeDetailContainer);

  teamAccordions.forEach((accordion: Element) => {
    const button = accordion.querySelector(
      `${TEAM_ACCORDION_CLASS_PREFIX}button`
    );
    const content = accordion.querySelector(
      `${TEAM_ACCORDION_CLASS_PREFIX}content`
    );
    if (!button || !content) {
      return;
    }

    const contentCloseButton = content.querySelector(
      `${TEAM_ACCORDION_CLASS_PREFIX}close-button`
    );
    if (!contentCloseButton) {
      return;
    }
    contentCloseButton.addEventListener("click", () =>
      closeTeamAccordion(accordion)
    );
    button.addEventListener("click", () => {
      if (!teamEmployeeDetailContainer) {
        return;
      }
      content.setAttribute(
        "style",
        `width:${teamEmployeeDetailContainer.clientWidth}px`
      );
      if (!accordion.classList.contains("team-accordion--open")) {
        openTeamAccordion(accordion);
      } else {
        closeTeamAccordion(accordion);
      }
    });
  });
}
