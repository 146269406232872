/**
 * DPI frontend menu logic.
 * Part of this logic is also in =>
 * /var/www/websites/deprojectinrichter/assets/js/slicingarts.js
 * TODO: Transfer all logic to this file
 */

const menuMobileButton = document.getElementById("menu-mobile-button");
const menuButton = document.getElementById("menu-open");
const menuMobileCloseButton = document.getElementById(
  "menu-mobile-close-button"
);
const menuTabButtons = document.querySelectorAll(".menu-tab-buttons");
const menuDesktopTabs = document.querySelectorAll(".desktop-sub-menu");
const menu = document.getElementById("menu");

// Open menu on mobile screen sizes
menuMobileButton?.addEventListener("click", () => {
  menu?.classList.toggle("active");
  setMenuToInitialState();
});
menuButton?.addEventListener("click", () => {
  menu?.classList.toggle("active");
  setMenuToInitialState();
});
// Close menu
menuMobileCloseButton?.addEventListener("click", () => {
  menu?.classList.remove("active");
  setMenuToInitialState();
});

function setMenuToInitialState() {
  if (!menu?.classList.contains("active")) {
    return;
  }
  // Return to initial state
  menuTabButtons.forEach((menuTabButton) => {
    menuTabButton.parentElement?.classList.remove("active");
  });
  menuDesktopTabs.forEach((menuDesktopTab) => {
    menuDesktopTab.classList.remove("show");
  });
  // The first item is products, which we want to enable by default
  menuTabButtons[0]?.parentElement?.classList.add("active");
  menuDesktopTabs[0]?.classList.add("show");
}

// Close menu on mobile screen sizes when clicked outside of menu
document.addEventListener("click", (event) => {
  if (!menu) {
    return;
  }
  if (event.target instanceof Element) {
    // Don't close menu if clicked inside menu or on menuMobileButton
    if (
      menu.contains(event.target) ||
      menuMobileButton?.contains(event.target)
    ) {
      return;
    }
    menu.classList.remove("active");
  }
});
