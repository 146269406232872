import Swiper from "swiper";

document.querySelectorAll('.expert[data-expandable="1"]').forEach(element => {
    const button = element.querySelector('button');
    button.addEventListener('click', event => {
        const isExpanded = element.dataset.isExpanded === "false";
        element.dataset.isExpanded = isExpanded.toString();
        element.querySelector('.expert-about').classList.toggle('hidden');
        button.classList.toggle('rotate-180');
    })
});

const ExpertBlockSwiper = new Swiper(".expert-block-swiper", {
    loop: true,
    speed: 220,
    effect: "fade",
    navigation: {
        nextEl: ".swipe-next",
        prevEl: ".swipe-previous",
    },
});

document.querySelectorAll("section.expert[data-in-region='1']").forEach(expert => {
    const options = expert.querySelector('.hidden.experts-in-region-options');

    document.querySelector('select').addEventListener('change', event => {
        const selectedValue = event.target.value;

        const swapPhoto = options.querySelector(`div[data-region="${selectedValue}"] img`).cloneNode(true);
        const swapCredentials = options.querySelector(`div[data-region="${selectedValue}"] div:nth-of-type(2)`).cloneNode(true);

        expert.querySelector('.expert-photo img').replaceWith(swapPhoto);
        expert.querySelector('.expert-credentials div').replaceWith(swapCredentials);
    });
});