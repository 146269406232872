/**
 * DPI ContentCreator dynamic ContentBlocks logic.
 */

const readMoreBlocks = document.querySelectorAll(".read-more-block");

readMoreBlocks.forEach((readMoreBlock) => {
  const button = readMoreBlock.querySelector("button");
  const buttonText = button?.querySelector("span");
  const chevron = button?.querySelector("i");
  const container = readMoreBlock.querySelector(".read-more-block__container");
  const innerContainer = readMoreBlock.querySelector(
    ".read-more-block__inner-container"
  );
  const closedText = button?.querySelector("span")?.innerText;

  let isOpen = false;
  button?.addEventListener("click", () => {
    if (!buttonText) {
      return;
    }
    chevron?.classList.toggle("rotate-180");
    if (isOpen) {
      buttonText.innerText = closedText ?? "";
      container?.removeAttribute("style");
      isOpen = false;
      return;
    }
    buttonText.innerText = buttonText.dataset.openText ?? "";
    container?.setAttribute(
      "style",
      `height: ${innerContainer?.clientHeight}px;`
    );
    isOpen = true;
  });
});
