/**
 * Archive page: set scroll
 */
var archiveFilter = document.getElementById("archiveFilter");

if (archiveFilter && document.body.clientWidth >= 1024) {
  var scrollPosition = getParam("scroll");

  if (scrollPosition / 1 > 115) {
    window.scrollTo(0, scrollPosition);
  }
}

/**
 * Archive page: submit filter on checkbox click
 */
var PARAM_PAGENO = "page";
var minPriceInput = document.querySelector(
  '#archiveFilter input[name="pricemin"]'
);
var maxPriceInput = document.querySelector(
  '#archiveFilter input[name="pricemax"]'
);
var mainnav = document.getElementById("header");

if (minPriceInput && maxPriceInput) {
  var KEYCODE_ENTER = 13;
  var priceSubmitBtn = document.getElementById("price-submit");

  var oldValues = [];
  oldValues[minPriceInput.name] = minPriceInput.value;
  oldValues[maxPriceInput.name] = maxPriceInput.value;

  var updateUrl = function () {
    var hasChanged =
      oldValues[minPriceInput.name] !== minPriceInput.value ||
      oldValues[maxPriceInput.name] !== maxPriceInput.value;

    if (!hasChanged) return;

    // Reset page number
    var href = removeParam(window.location.href, PARAM_PAGENO);
    href = setParam(href, minPriceInput.name, minPriceInput.value);
    href = setParam(href, maxPriceInput.name, maxPriceInput.value);
    if (archiveFilter && document.body.clientWidth >= 1024) {
      // Reset scroll
      href = removeParam(href, "scroll");
      href = setParam(href, "scroll", mainnav.offsetTop);
    }

    window.location.href = href;
  };

  for (
    let minInputIndex = 0;
    minInputIndex < minPriceInput.length;
    minInputIndex++
  ) {
    var minInput = minPriceInput[minInputIndex];
    minInput.addEventListener("keydown", (e) => {
      if (e.keyCode === KEYCODE_ENTER) {
        e.preventDefault();
        updateUrl();
      }
    });
  }

  for (
    let maxInputIndex = 0;
    maxInputIndex < maxPriceInput.length;
    maxInputIndex++
  ) {
    var maxInput = maxPriceInput[maxInputIndex];
    maxInput.addEventListener("keydown", (e) => {
      if (e.keyCode === KEYCODE_ENTER) {
        e.preventDefault();
        updateUrl();
      }
    });
  }

  // Handle price submit button press
  if (priceSubmitBtn) {
    priceSubmitBtn.addEventListener("click", (e) => {
      e.preventDefault();
      updateUrl();
    });
  }
}

var archiveFilterBoxes = Array.prototype.slice.call(
  document.querySelectorAll('#archiveFilter input[type="checkbox"]')
);
var circulair = document.querySelectorAll(
  `input[type=checkbox][name='f[Circulair][]']`
);

if (circulair.length) {
  archiveFilterBoxes.push(circulair[0]);
}
archiveFilterBoxes.forEach((checkbox) =>
  checkbox.addEventListener("click", function () {
    var name = checkbox.name;
    var value = checkbox.value;

    console.log(name, value);

    // Reset page number
    var href = removeParam(window.location.href, PARAM_PAGENO);

    if (archiveFilter && document.body.clientWidth >= 1024) {
      href = removeParam(window.location.href, "scroll");
      href = setParam(href, "scroll", mainnav.offsetTop);
    }

    if (checkbox.checked) {
      window.location.href = appendParam(href, name, value);
    } else {
      window.location.href = removeParam(href, name, value);
    }
  })
);

var sortSelector = document.getElementById("sort-dropdown");
if (sortSelector) {
  sortSelector.addEventListener("change", function () {
    var name = sortSelector.name;
    var value = sortSelector.value;

    // Reset page number
    var href = removeParam(window.location.href, PARAM_PAGENO);

    if (archiveFilter && document.body.clientWidth >= 1024) {
      href = removeParam(window.location.href, "scroll");
      href = setParam(href, "scroll", mainnav.offsetTop);
    }

    window.location.href = setParam(href, name, value);
  });
}

var urlencode = function (str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, escape);
};

var setParam = function (url, name, value) {
  // Create dummy element to parse / change URL
  var dummy = document.createElement("a");
  dummy.href = url;

  // Check if value is already set
  if (
    dummy.search.match(
      new RegExp("(\\?|\\&)" + name + "=" + value + "($|\\?|\\&)")
    )
  ) {
    return dummy.href;
  }

  // Replace value in URL
  var replacedSearch = dummy.search.replace(
    new RegExp("(\\?|\\&)" + name + "=[^&]*"),
    "$1" + name + "=" + value
  );

  if (!dummy.search.length || replacedSearch === dummy.search) {
    // Append parameter to URL
    dummy.href = appendParam(dummy.href, name, value);
  } else {
    // Set replaced URL
    dummy.search = replacedSearch;
  }

  return dummy.href;
};

var appendParam = function (url, name, value) {
  // Create dummy element to parse / change URL
  var dummy = document.createElement("a");
  dummy.href = url;

  var paramBody = urlencode(name) + "=" + urlencode(value);
  dummy.search += dummy.search.length ? `&${paramBody}` : `?${paramBody}`;
  return dummy.href;
};

var removeParam = function (url, name, value = null) {
  // Create dummy element to parse / change URL
  var dummy = document.createElement("a");
  dummy.href = url;

  var valueMatcher = value !== null ? urlencode(value) : "[^\\&]*";

  // Remove URL parameter
  dummy.search = dummy.search.replace(
    new RegExp("(\\?|\\&)" + urlencode(name) + "=" + valueMatcher),
    ""
  );

  return dummy.href;
};

function getParam(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const lessbrandslisttrigger = document.getElementById("lessbrandslisttrigger");
const morebrandslisttrigger = document.getElementById("morebrandslisttrigger");
const morebrandslist = document.getElementById("morebrandslist");

if (morebrandslisttrigger && morebrandslist && lessbrandslisttrigger) {
  morebrandslisttrigger.addEventListener("click", () => {
    lessbrandslisttrigger.classList.remove("hidden");
    morebrandslisttrigger.classList.add("hidden");
    morebrandslist.classList.remove("hidden");
  });

  lessbrandslisttrigger.addEventListener("click", () => {
    lessbrandslisttrigger.classList.add("hidden");
    morebrandslisttrigger.classList.remove("hidden");
    morebrandslist.classList.add("hidden");
  });
}
